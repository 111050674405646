<template>
  <div class="text-a-neutral font-sans tracking-wide">
    <DashboardNav class="hidden md:flex" />
    <DashboardNavMobile class="flex md:hidden" />
    <div class="flex flex-row w-full">
      <div class="w-2/12 hidden md:flex flex-none md:w-2/12 pt-8 border-r-2">
        <BaseDashboardSideMenu
          :menuItems="menuItems"
          dashboardPathName="dashboard-islamic-centers"
        >
          <template v-slot:actionButton>
            <BaseButton
            primary-color-class="a-neutral-dim"
              text-color-class="a-neutral-dark"
              width-class="w-full"
              text-size-class="text-sm xl:text-base"
              @click="showDeactivationModal()"
              v-if="islamicCenter && islamicCenter?.status != 'Deactivated'"
            >
              <span class="flex flex-row w-full items-center justify-center">
                <Icon
                  name="mdi:archive-remove-outline"
                  class="px-0.5 text-2xl text-a-neutral-dark"
                />
                <span class="font-semibold">Deactivate</span>
              </span> 
            </BaseButton>
          </template>
        </BaseDashboardSideMenu>
      </div>
      <div class="flex md:hidden">
        <BaseDashboardSideMenuMobile
          :menuItems="menuItems"
          dashboardPathName="dashboard-islamic-centers"
        >

          <template v-slot:actionButton>
            <BaseButton
            primary-color-class="a-neutral-dim"
              text-color-class="a-neutral-dark"
              width-class="w-full"
              text-size-class="text-sm xl:text-base"
              @click="showDeactivationModal()"
              v-if="islamicCenter && islamicCenter?.status != 'Deactivated'"
            >
            <span class="flex flex-row w-full items-center justify-center">
                <Icon
                  name="mdi:archive-remove-outline"
                  class="px-0.5 text-2xl text-a-neutral-dark"
                />
                <span class="font-semibold">Deactivate</span>
              </span> 
            </BaseButton>
          </template>

        </BaseDashboardSideMenuMobile>
      </div>

      <div class="flex flex-col w-full md:w-10/12 pt-8">
        <slot :key="route.fullPath" />
      </div>
      <BaseModal ref="islamicCenterDeactivationModalRef">
        <DashboardIslamicCenterDeactivationModalTemplate
          :islamic-center="islamicCenter"
          @closeModal="hideDeactivationModal()"
        />
      </BaseModal>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BaseModal } from '~/types';

const route = useRoute();

const menuItems = ref<DashboardMenuItem[]>([
  {
    title: "All Details",
    link: {
      routeName: "dashboard-islamic-centers-slug",
      routeParams: { slug: route.params.slug },
    },
    icon: "mdi:menu",
  },
  {
    title: "Edit",
    link: {
      routeName: "dashboard-islamic-centers-slug-edit",
      routeParams: { slug: route.params.slug },
    },
    icon: "mdi:clipboard-edit-outline",
  },
  {
    title: "Gallery",
    link: {
      routeName: "dashboard-islamic-centers-slug-gallery",
      routeParams: { slug: route.params.slug },
    },
    icon: "mdi:image-album",
  },
  {
    title: "Prayers Timing",
    link: {
      routeName: "dashboard-islamic-centers-slug-prayer-timing",
      routeParams: { slug: route.params.slug },
    },
    icon: "material-symbols:prayer-times",
  },
  {
    title: "Jummah Prayer Timing",
    link: {
      routeName: "dashboard-islamic-centers-slug-jummah-prayer-timing",
      routeParams: { slug: route.params.slug },
    },
    icon: "hugeicons:prayer-rug-02",
  },
  {
    title: "Eid Al Adha Prayer Timing",
    link: {
      routeName: "dashboard-islamic-centers-slug-eid-al-adha-prayer-timing",
      routeParams: { slug: route.params.slug },
    },
    icon: "game-icons:goat",
  },
  {
    title: "Eid Al Fitr Prayer Timing",
    link: {
      routeName: "dashboard-islamic-centers-slug-eid-al-fitr-prayer-timing",
      routeParams: { slug: route.params.slug },
    },
    icon: "hugeicons:eid-mubarak",
  },
]);

const islamicCenter = await useIslamicCentersDataManager().getCurrentDashboardIslamicCenter();
const key = computed(() => {
  return useRoute().fullPath;
});

watch(() => route.params.slug, () => {
  menuItems.value.forEach(
    (menuItem) => (menuItem.link.routeParams.slug = islamicCenter.value?.slug as string)
  );
},
);

const islamicCenterDeactivationModalRef = ref<BaseModal | null>(null);

function showDeactivationModal() {
  if (islamicCenterDeactivationModalRef.value) {
    islamicCenterDeactivationModalRef.value.show();
  }
}
function hideDeactivationModal() {
  if (islamicCenterDeactivationModalRef.value) {
    islamicCenterDeactivationModalRef.value.hide();
  }
}
</script>

<style></style>
