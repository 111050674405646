<template>
  <div class="flex flex-col">
    <p class="flex flex-row items-center text-lg font-semibold">
      <span class="text-base md:text-lg">Are you sure, you want to deactivate this islamic center?</span>
    </p>
    <p class="text-sm md:text-base pt-3 pb-5">
      By Deactivating, you will never be able to get this islamic center back active again.
    </p>
    <div class="flex flex-row w-full">
      <BaseButton
      text-size-class="text-sm md:text-base"
      width-class="w-1/2"
      primary-color-class="a-error"
      text-color-class="white"
        margin-classes="my-2 mx-1"
        @click.native="deactivateIslamicCenter()"
        :loading="deactivateIslamicCenterLoading"
        loadingColor="#EF4444"
      >
        Yes, Deactivate
      </BaseButton>
      <BaseButton
      isTextOnly
      text-color-class="a-neutral-light"
      width-class="w-1/2"
      text-size-class="text-sm md:text-base"
      margin-classes="my-2 mx-1"
        @click.native="$emit('closeModal')"
      >
        No
      </BaseButton>
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>
import type { DashboardIslamicCenter } from '~/types';

const deactivateIslamicCenterLoading = ref(false);

const props = defineProps<{
  classifiedAd: DashboardIslamicCenter | null;
}>();

const emit = defineEmits(['closeModal']);

async function deactivateIslamicCenter() {
  deactivateIslamicCenterLoading.value = true;
  await useIslamicCentersDataManager().deactivateCurrentDashboardIslamicCenter();
  emit('closeModal');
  deactivateIslamicCenterLoading.value = false
}
</script>

<style></style>
